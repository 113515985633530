<template>
  <section id="content" class="flex flex-col bg-slate-900">
    <div
      class="container flex flex-col px-2 pb-8 pt-10 lg:mx-auto lg:px-6 lg:pb-24 lg:pt-20"
    >
      <div class="flex w-full flex-col items-center text-center">
        <img src="@/assets/image/icon.png" class="h-16 rounded-lg md:h-16" />

        <p class="md:text-md mt-4 self-center text-xl text-white md:text-2xl">
          Kontak Kami
        </p>

        <p class="mt-4 text-xs md:text-sm text-gray-400">
          Mari terhubung dan berbicara lebih banyak tentang kemungkinan-kemungkinan yang dapat kita ciptakan bersama
        </p>
        <div class="bg-kira-secondary mt-4 h-1 w-32"></div>
      </div>
    </div>

    <div class="container flex flex-col px-2 pb-20 lg:mx-auto lg:px-6 lg:pb-32">
      <div class="container flex flex-col px-2 lg:mx-auto lg:px-20">
        <p class="text-md text-white md:text-xl">Hubungi Kami</p>
      </div>

      <div
        class="container mt-2 flex flex-col px-2 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <Form
          v-slot="{ errors }"
          :validation-schema="sendEmailSchema"
          @submit="sendEmail"
        >
          <div class="my-4">
            <label class="block">
              <p class="mb-3 text-sm text-gray-300">Nama Anda</p>
              <Field v-slot="{ field }" v-model="name" name="name">
                <input
                  v-bind="field"
                  type="text"
                  class="form-control mb-1 block w-full rounded-lg border border-solid border-gray-200 bg-slate-800 bg-clip-padding py-2 px-3 text-base font-normal text-gray-300 transition ease-in-out focus:border-primary focus:bg-slate-900 focus:text-gray-300 focus:outline-none md:py-3 md:px-4"
                  placeholder="e.g. Ahmad Muhyidin"
                />
              </Field>
              <span class="text-kira-danger text-sm">
                <template v-if="errors.name !== undefined">
                  {{ errors.name }}
                </template>
              </span>
            </label>
          </div>

          <div class="mt-4">
            <label class="block">
              <p class="mb-3 text-sm text-gray-300">Alamat Email</p>
              <Field v-slot="{ field }" v-model="email" name="email">
                <input
                  v-bind="field"
                  type="email"
                  class="form-control mb-1 block w-full rounded-lg border border-solid border-gray-200 bg-slate-800 bg-clip-padding py-2 px-3 text-base font-normal text-gray-300 transition ease-in-out focus:border-primary focus:bg-slate-900 focus:text-gray-300 focus:outline-none md:py-3 md:px-4"
                  placeholder="e.g. j....idin@mail.com"
                />
              </Field>
              <span class="text-kira-danger text-sm">
                <template v-if="errors.email !== undefined">
                  {{ errors.email }}
                </template>
              </span>
            </label>
          </div>

          <div class="my-4">
            <label class="block">
              <p class="mb-3 text-sm text-gray-300">Subjek</p>
              <Field v-slot="{ field }" v-model="subject" name="subject">
                <input
                  v-bind="field"
                  type="text"
                  class="form-control mb-1 block w-full rounded-lg border border-solid border-gray-200 bg-slate-800 bg-clip-padding py-2 px-3 text-base font-normal text-gray-300 transition ease-in-out focus:border-primary focus:bg-slate-800 focus:text-gray-300 focus:outline-none md:py-3 md:px-4"
                  placeholder="Masukan subjek..."
                />
              </Field>
              <span class="text-kira-danger text-sm">
                <template v-if="errors.subject !== undefined">
                  {{ errors.subject }}
                </template>
              </span>
            </label>
          </div>

          <div class="my-4">
            <label class="block">
              <p class="mb-3 text-sm text-gray-300">Pesan</p>
              <Field v-slot="{ field }" v-model="message" name="message">
                <textarea
                  v-bind="field"
                  type="text"
                  class="form-control block w-full rounded-lg border border-solid border-gray-200 bg-slate-800 bg-clip-padding py-2 px-3 text-base font-normal text-gray-300 transition ease-in-out focus:border-primary focus:bg-slate-800 focus:text-gray-300 focus:outline-none md:py-3 md:px-4"
                  rows="4"
                  placeholder="Apa yang bisa membantu Anda?"
                ></textarea>
              </Field>
              <span class="text-kira-danger text-sm">
                <template v-if="errors.message !== undefined">
                  {{ errors.message }}
                </template>
              </span>
            </label>
          </div>

          <button
            class="bg-kira-primary text-kira-light -darker mt-6 inline-flex w-full items-center justify-center rounded-lg border border-transparent px-6 py-3 opacity-100 drop-shadow-2xl transition duration-150 ease-in-out hover:opacity-90 focus:outline-none md:py-4"
            type="submit"
          >
            <svg
              v-if="isLoading"
              class="text-kira-light mr-3 -ml-1 h-5 w-5 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <p v-else class="text-sm lg:text-base">Kirim Pesan</p>
          </button>
        </Form>
      </div>
    </div>
  </section>
</template>

<script>
import * as Yup from 'yup'
import globalMixin from '@/mixins/global.js'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
  name: 'ContactUsPage',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  mixins: [globalMixin],
  data: () => ({
    isLoading: false,
    warungprabowoEmail: 'prabowo.warung@gmail.com',
    name: '',
    email: '',
    subject: '',
    message: '',
    sendEmailSchema: Yup.object().shape({
      name: Yup.string()
        .typeError('Mohon masukan nama lengkap')
        .required('Mohon isi nama lengkap'),
      email: Yup.string()
        .required('Mohon masukan alamat email')
        .email('Mohon masukan alamat email yang benar'),
      subject: Yup.string()
        .typeError('Mohon masukan subjek')
        .required('Mohon isi subjek'),
      message: Yup.string()
        .typeError('Mohon masukan pesan')
        .required('Mohon isi pesan'),
    }),
  }),
  methods: {
    async sendEmail() {
      window.open
      this.isLoading = true
      window.location.href = `mailto:${this.warungprabowoEmail}?subject=${this.subject}&body=${this.message}`
    },
  },
}
</script>
