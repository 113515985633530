<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          OUR SERVICES
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Solutions for Sales
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Get a detailed inisghts on the data of your product sales like never
          before with real
        </p>
        <p class="mt-2 text-xs text-gray-400">
          data directly from the traditional retail market.
        </p>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/home-1.png" class="w-full rounded-xl" />
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informations" :key="item.id" class="flex w-full">
          <CardThree :title="item.title" :description="item.description" />
        </div>
      </div>

      <!-- Offers -->
      <div class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            WHAT WE OFFER
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap px-6 md:flex-row lg:px-32">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/2 lg:p-8"
        >
          <CardTwo :title="item.title" :description="item.description">
            <OfferUser v-if="item.icon === 'USER'" class="h-10" />
            <OfferSearch v-if="item.icon === 'SEARCH'" class="h-10" />
            <OfferNewspaper v-if="item.icon === 'NEWSPAPER'" class="h-10" />
            <OfferLayer v-if="item.icon === 'LAYER'" class="h-10" />
          </CardTwo>
        </div>
      </div>

      <div class="mt-20 flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informationsTwo" :key="item.id" class="flex w-full">
          <CardThree
            :title="item.title"
            :description="item.description"
            :show-icon="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferUser from '@/assets/svg/offers/user.svg'
import OfferSearch from '@/assets/svg/offers/search.svg'
import OfferNewspaper from '@/assets/svg/offers/newspaper.svg'
import OfferLayer from '@/assets/svg/offers/layer.svg'

export default {
  name: 'ServiceSolutionForSalesPage',
  components: {
    CardTwo,
    CardThree,
    OfferUser,
    OfferSearch,
    OfferNewspaper,
    OfferLayer,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Actionable Real Time Information',
        description:
          'The Traditional channel consisting of hundreds of thousands of small stores is by far your single largest channel yet its extremely difficult to obtain actionable and timely sales information from it. Warung Prabowo’s solutions for sales give you real-time sales and inventory data figures to track daily sales, in-store availability and overall channel effectiveness both for your brands and competitors. Furthermore, our online platform allows for sales messages to be promoted to an exactly targeted audience.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'The Warung Prabowo Advantage',
        description:
          'We collect and track real-time sales data from tens of thousands of traditional retailers and make this information available to you to consume in real-time either through links to your existing analytics solutions or using our insight and analytics workbench. Our unique platform allows you not only to analyze past data but connect in real-time with small retailers to conduct real-time experiments, surveys, and sentiment analysis. Obtain the feedback and information you need in real-time to make your marketing much more effective and generate a much higher return on marketing spend.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Executive Management',
        description:
          'Track sales and sales trends across your entire portfolio to enable fast and effective executive decisions.  Use our data and analytics to perform in-depth simulation and planning activities.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'Sales Management',
        description:
          'Track your sales performance and measure and assess the effectiveness of your sales execution strategies and obtain real-time feedback to more effectively manage your sales teams.',
        icon: 'NEWSPAPER',
      },
      {
        id: 3,
        title: 'Sales Execution',
        description:
          'Utilize the insights from Warung Prabowo’s platform to create perfect sales execution strategies.  Leverage the power of the platform to understand sales velocities, detailed territory analysis,  product placement, POS merchandise availability and much more.',
        icon: 'SEARCH',
      },
      {
        id: 4,
        title: 'Demand Management',
        description:
          'Increase the accuracy and precision of your demand management by using the power of the latest technology in the Warung Prabowo platform.  From real-time demand sensing to predictive analytics based on detailed and relevant causal information of the effect of yours and competitors` promotional, new product and sales activities.',
        icon: 'LAYER',
      },
    ],
  }),
  methods: {},
}
</script>
