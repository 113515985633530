<template>
  <section id="content" class="flex flex-col bg-slate-900">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-300 md:text-base">
          Kategori Mitra Warung Prabowo
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-white md:text-2xl">
          Kami memiliki 4 kategori Mitra Warung Prabowo
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-32"></div>
      </div>

      <!-- <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img
            src="@/assets/image/warung/home-transparent.png"
            class="w-full rounded-xl"
          />
        </div>
      </div> -->
    </div>

    <div class="mb-90 flex w-full flex-col">
      <div class="relative flex bg-slate-700 py-20">
        <!-- <LinesDotted class="absolute w-full" /> -->
        <div class="z-20 flex w-full flex-col px-6 lg:px-32">
          <!-- <div class="mb-10 flex w-full flex-col items-center text-center">
            <p class="text-sm tracking-widest text-gray-300 md:text-base">
              MEMBERSHIPS PLAN
            </p>
          </div> -->
          <div class="flex w-full">
            <!-- <div class="w-1/4">
              <div class="flex flex-col">
                <div class="mt-14 flex py-4">
                  <p class="text-xs md:text-sm">
                    Early Access to Platform Data
                  </p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Free In-Platform Warung Prabowo</p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Influence Design & Insights</p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Exclusive Discount</p>
                </div>
              </div>
            </div> -->
            <div class="sm:flex md:grid md:grid-cols-2 md:gap-4 md:gap-y-8 lg:grid-cols-4">
              <div class="flex w-full flex-col justify-center">
                <div
                  class="flex h-32 w-full flex-col rounded-tl-xl rounded-tr-xl bg-slate-900 py-4 md:rounded-xl"
                >
                  <div class="flex items-center justify-center border-b-2 pb-2">
                    <MemberShipBronze class="h-4" />
                    <p class="text-md ml-2 font-black text-white">R1</p>
                  </div>
                  <div class="mt-2 justify-center px-4 text-center">
                    <p class="text-sm text-gray-300">
                      Agen & Grosir dengan quantity produk yang besar
                    </p>
                  </div>
                </div>
                <div class="flex w-full justify-center md:mt-2 lg:mt-8">
                  <img
                    src="@/assets/image/warung/r1.png"
                    class="max-h-min w-full rounded-bl-xl rounded-br-xl md:rounded-xl"
                  />
                </div>
              </div>
              <div class="mt-16 flex w-full flex-col justify-center md:mt-0">
                <div
                  class="flex h-32 w-full flex-col rounded-tl-xl rounded-tr-xl bg-slate-900 py-4 md:rounded-xl"
                >
                  <div class="flex items-center justify-center border-b-2 pb-2">
                    <MemberShipSilver class="h-4" />
                    <p class="text-md ml-2 font-black text-white">R2</p>
                  </div>
                  <div class="mt-2 justify-center px-4 text-center">
                    <p class="text-sm text-gray-300">
                      Toko dengan memiliki tempat permanen
                    </p>
                  </div>
                </div>
                <div class="flex w-full justify-center md:mt-2 lg:mt-8">
                  <img
                    src="@/assets/image/warung/r2.png"
                    class="max-h-min w-full rounded-bl-xl rounded-br-xl md:rounded-xl"
                  />
                </div>
              </div>
              <div class="mt-16 flex w-full flex-col justify-center md:mt-0">
                <div
                  class="flex h-32 w-full flex-col rounded-tl-xl rounded-tr-xl bg-slate-900 py-4 md:rounded-xl"
                >
                  <div class="flex items-center justify-center border-b-2 pb-2">
                    <MemberShipGold class="h-4" />
                    <p class="text-md ml-2 font-black text-white">R3</p>
                  </div>
                  <div class="mt-2 justify-center px-4 text-center">
                    <p class="text-sm text-gray-300">Warung Rombong</p>
                  </div>
                </div>
                <div class="flex w-full justify-center md:mt-2 lg:mt-8">
                  <img
                    src="@/assets/image/warung/r3.png"
                    class="max-h-min w-full rounded-bl-xl rounded-br-xl md:rounded-xl"
                  />
                </div>
              </div>
              <div class="mt-16 flex w-full flex-col justify-center md:mt-0">
                <div
                  class="flex h-32 w-full flex-col rounded-tl-xl rounded-tr-xl bg-slate-900 py-4 md:rounded-xl"
                >
                  <div class="flex items-center justify-center border-b-2 pb-2">
                    <MemberShipBronze class="h-4" />
                    <p class="text-md ml-2 font-black text-white">Modern Market</p>
                  </div>
                  <div class="mt-2 justify-center px-4 text-center">
                    <p class="text-sm text-gray-300">
                      Yang bekerja sama dengan koperasi pabrik
                    </p>
                  </div>
                </div>
                <div class="flex w-full justify-center md:mt-2 lg:mt-8">
                  <img
                    src="@/assets/image/warung/mini-market.png"
                    class="max-h-min w-full rounded-bl-xl rounded-br-xl md:rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferUser from '@/assets/svg/offers/user.svg'
import CircleCheck from '@/assets/svg/circle-check.svg'
import LinesDotted from '@/assets/svg/lines-dotted.svg'
import MemberShipBronze from '@/assets/svg/membership/bronze.svg'
import MemberShipSilver from '@/assets/svg/membership/silver.svg'
import MemberShipGold from '@/assets/svg/membership/gold.svg'

export default {
  name: 'ServiceSolutionForSupplyChainPage',
  components: {
    CardTwo,
    CardThree,
    OfferUser,
    CircleCheck,
    LinesDotted,
    MemberShipBronze,
    MemberShipSilver,
    MemberShipGold,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Actionable Real Time Information',
        description:
          'The Traditional channel consisting of hundreds of thousands of small stores is by far your single largest channel yet its extremely difficult to obtain actionable and timely supply chain information from it. Warung Prabowo’s solutions for Supply Chain gives you real-time sales and inventory data figures to track daily sales, in-store availability and overall channel effectiveness by distribution channel whether direct or indirect. Make your demand planning, distribution planning and in-store availability far more effective by using our unique historical and predictive analytical data and toolset.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'The Warung Prabowo Advantage',
        description:
          'We collect and track real-time sales data from tens of thousands of traditional retailers and make this information available to you to consume in real-time either through links to your existing analytics solutions or using our insight and analytics workbench. Our unique platform allows you not only to analyze past data but connect in real-time with small retailers to conduct real-time experiments, surveys, and sentiment analysis. Obtain the feedback and information you need in real-time to make your marketing much more effective and generate a much higher return on marketing spend.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Supply Chain Leader',
        description:
          'Monitor the effectiveness of your supply chain down to the crucial ‘last mile’ of the traditional channel.  use the data from Warung Prabowo to more effectively plan and manage your Supply Chain activities to improve service levels at the store level and to lower costs.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'S&OP Leader',
        description:
          'Use the data from the Warung Prabowo platform to make your S&OP planning much more effective by utilizing the trend and actual data in the Warung Prabowo platform to more effectively plan supply/demand decisions, promotional campaigns, and new product launches.',
        icon: 'USER',
      },
      {
        id: 3,
        title: 'Distribution Manager',
        description:
          'Warung Prabowo’s inventory and sales velocity data can be used to more effectively plan and manage your distribution channel and assets to ensure the highest service levels at the lowest cost.  Effectively manage your channel partners or view new direct delivery options by using our real time data to plan, simulate and act effectively.',
        icon: 'USER',
      },
      {
        id: 4,
        title: 'Transportation Manager',
        description:
          'Incorporate our data into your transportation planning to better prioritize and schedule routes, take advantage of reverse logistics opportunities and better manage returnable packaging transportation.',
        icon: 'USER',
      },
    ],
  }),
  methods: {},
}
</script>
