<template>
  <div class="bg-slate-900">
    <nav
      class="container mx-auto px-6 py-8 lg:flex lg:items-center lg:justify-between"
    >
      <div class="flex items-center justify-between">
        <router-link
          to="/"
          class="hover:text-kira-primary text-xl font-bold text-white hover:text-primary md:text-2xl"
        >
          <img src="@/assets/image/logo.png" class="h-10" />
          <!-- <LogoIcon class="h-10 text-black" /> -->
        </router-link>

        <!-- Mobile menu button -->
        <div
          class="flex lg:hidden"
          @click="
            ;(showMenu = !showMenu),
              (serviceOpen = !showMenu),
              (aboutOpen = !showMenu)
          "
        >
          <button
            type="button"
            class="hover:text-kira-primary text-white hover:text-gray-400 focus:text-gray-400 focus:outline-none"
          >
            <HamburgerIcon
              v-if="showMenu === false"
              class="h-6 w-6 fill-current"
            />
            <CloseIcon v-else class="h-6 w-6 fill-current" />
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul
        :class="showMenu ? 'flex' : 'hidden'"
        class="mt-4 flex-col space-y-4 lg:mt-0 lg:flex lg:flex-row lg:items-center lg:space-y-0 lg:space-x-12 xl:space-x-16"
      >
        <li
          class="hover:text-kira-primary hover:text-kira-primary cursor-pointer text-sm font-bold text-white"
          :class="$route.name === 'home' && 'text-kira-primary'"
          @click="openRoute('home')"
        >
          Beranda
        </li>
        <!-- <li
          class="hover:text-kira-primary relative cursor-pointer text-sm font-bold text-white"
          :class="
            [
              'service-solution-for-marketing',
              'service-solution-for-sales',
              'service-solution-for-supply-chain',
            ].includes($route.name) && 'text-kira-primary'
          "
          @click="serviceOpen = true"
          @mouseover="serviceOpen = true"
        >
          <span class="hover:text-kira-primary">Layanan</span>

          <ArrowDownIcon
            class="ml-1 inline h-4 w-4"
            :class="{ 'rotate-180': serviceOpen, 'rotate-0': !serviceOpen }"
          />
          <div
            v-show="serviceOpen"
            class="relative right-0 z-50 mt-2 w-full origin-top-right rounded-md lg:absolute lg:w-56"
            @mouseover="serviceOpen = true"
            @mouseleave="serviceOpen = false"
          >
            <div class="rounded-md bg-slate-900 px-2 py-2 shadow">
              <a
                href="#"
                class="focus:shadow-outline hover:text-kira-primary mt-2 block rounded-lg bg-transparent px-4 py-2 text-sm font-semibold text-white focus:outline-none md:mt-0"
                :class="
                  $route.name === 'service-solution-for-marketing' &&
                  'text-kira-primary'
                "
                @click="openRoute('service-solution-for-marketing')"
                >Solusi untuk Pemasaran</a
              >
              <a
                href="#"
                class="focus:shadow-outline hover:text-kira-primary mt-2 block rounded-lg bg-transparent px-4 py-2 text-sm font-semibold text-white focus:outline-none md:mt-0"
                :class="
                  $route.name === 'service-solution-for-sales' &&
                  'text-kira-primary'
                "
                @click="openRoute('service-solution-for-sales')"
                >Solusi untuk Penjualan</a
              >
              <a
                href="#"
                class="focus:shadow-outline hover:text-kira-primary mt-2 block rounded-lg bg-transparent px-4 py-2 text-sm font-semibold text-white focus:outline-none md:mt-0"
                :class="
                  $route.name === 'service-solution-for-supply-chain' &&
                  'text-kira-primary'
                "
                @click="openRoute('service-solution-for-supply-chain')"
                >Solusi untuk Rantai Pasok</a
              >
            </div>
          </div>
        </li> -->

        <!-- <li
          class="hover:text-kira-primary hover:text-kira-primary cursor-pointer text-sm font-bold text-white"
          :class="$route.name === 'platform' && 'text-kira-primary'"
          @click="openRoute('platform')"
        >
          Platform
        </li> -->
        <li
          class="hover:text-kira-primary hover:text-kira-primary cursor-pointer text-sm font-bold text-white"
          :class="$route.name === 'partnership' && 'text-kira-primary'"
          @click="openRoute('partnership')"
        >
          Kemitraan
        </li>
        <li
          class="hover:text-kira-primary hover:text-kira-primary cursor-pointer text-sm font-bold text-white"
          :class="
            $route.name === 'about-us-our-vision-mission' && 'text-kira-primary'
          "
          @click="openRoute('about-us-our-vision-mission')"
        >
          Visi dan Misi kami
        </li>
        <!-- <li
          class="hover:text-kira-primary relative cursor-pointer text-sm font-bold text-white"
          :class="
            ['about-us-our-team', 'about-us-our-vision-mission'].includes(
              $route.name
            ) && 'text-kira-primary'
          "
          @click="aboutOpen = true"
          @mouseover="aboutOpen = true"
        >
          <span class="hover:text-kira-primary">Tentang Kami</span>

          <ArrowDownIcon
            class="ml-1 text-white inline h-4 w-4"
            :class="{ 'rotate-180': aboutOpen, 'rotate-0': !aboutOpen }"
          />
          <div
            v-show="aboutOpen"
            class="relative right-0 z-50 mt-2 w-full origin-top-right rounded-md lg:absolute lg:w-56"
            @mouseover="aboutOpen = true"
            @mouseleave="aboutOpen = false"
          >
            <div class="rounded-md bg-slate-900 px-2 py-2 shadow">
              <a
                href="#"
                class="focus:shadow-outline hover:text-kira-primary mt-2 block rounded-lg bg-transparent px-4 py-2 text-sm font-semibold text-white focus:outline-none md:mt-0"
                :class="
                  $route.name === 'about-us-our-team' && 'text-kira-primary'
                "
                @click="openRoute('about-us-our-team')"
                >Tim Luar Biasa Kami</a
              >
              <a
                href="#"
                class="focus:shadow-outline hover:text-kira-primary mt-2 block rounded-lg bg-transparent px-4 py-2 text-sm font-semibold text-white focus:outline-none md:mt-0"
                :class="
                  $route.name === 'about-us-our-vision-mission' &&
                  'text-kira-primary'
                "
                @click="openRoute('about-us-our-vision-mission')"
                >Visi dan Misi kami</a
              >
            </div>
          </div>
        </li> -->
        <!-- <li
          class="hover:text-kira-primary cursor-pointer text-sm font-bold text-white hover:text-kira-primary"
          :class="$route.name === 'insights' && 'text-kira-primary'"
          @click="openRoute('insights')"
        >
          Insights
        </li> -->
        <li
          class="hover:text-kira-primary hover:text-kira-primary cursor-pointer text-sm font-bold text-white"
          :class="$route.name === 'contact-us' && 'text-kira-primary'"
          @click="openRoute('contact-us')"
        >
          Kontak Kami
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
// import LogoIcon from '@/assets/svg/logo.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'
import HamburgerIcon from '@/assets/svg/hamburger.svg'
import CloseIcon from '@/assets/svg/close.svg'

export default {
  name: 'NavbarDefault',
  components: { ArrowDownIcon, HamburgerIcon, CloseIcon },
  mixins: [globalMixin],
  data: () => ({ showMenu: false, serviceOpen: false, aboutOpen: false }),
  methods: {},
}
</script>
