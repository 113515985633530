<template>
  <div class="py-12 lg:flex lg:items-center lg:justify-between lg:py-16">
    <div class="flex w-full flex-col">
      <div class="flex flex-col px-8">
        <h2
          class="font-dreamland text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          dreamland ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-creamy text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          creamy ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-exmouth text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          exmouth ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-geraldine text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          geraldine ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-roasting text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          roasting ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-yaquote text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          yaquote ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-warungprabowo text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          warungprabowo ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-josefin text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          josefin ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-bellefair text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          bellefair ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-zeyada text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          zeyada ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-dancing text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          dancing ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
        <br />
        <h2
          class="font-satisfy text-3xl font-semibold text-gray-900 sm:text-4xl"
        >
          satisfy ~ warungprabowo
          <br />
          <span class="text-2xl text-indigo-600">Novi & Idin</span>
        </h2>
      </div>
      <div class="mt-8 flex flex-col px-8 lg:mt-12">
        <h2>Hello {{ user.name }}</h2>

        <form data-testid="add-items" @submit.prevent="addItemToCart">
          <input
            v-model="itemName"
            type="text"
            class="focus:border-ink-primary focus:ring-primary-darker mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50"
          />
          <button
            class="text-ink-light mt-3 block w-full rounded-lg bg-indigo-500 px-6 py-2 text-lg font-semibold hover:bg-indigo-600"
          >
            Add
          </button>
        </form>

        <form @submit.prevent="buy">
          <ul data-testid="items">
            <li v-for="item in cart.items" :key="item.name">
              <div class="mt-4 grid w-full grid-cols-2 gap-4">
                <div class="align-center flex items-center">
                  <span class="text-md font-bold"></span> {{ item.name }} ({{
                    item.amount
                  }})
                </div>
                <div>
                  <button
                    class="bg-ink-danger hover:bg-ink-danger text-ink-light block rounded-lg px-6 py-1 text-lg font-semibold"
                    @click="cart.removeItem(item.name)"
                  >
                    X
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <div class="mt-4 flex">
            <div class="grid w-full grid-cols-2 gap-4">
              <button
                class="text-ink-light block w-full rounded-lg bg-blue-500 px-6 py-2 text-lg font-semibold hover:bg-blue-600"
                :disabled="!user.name"
              >
                Buy
              </button>

              <button
                class="bg-ink-danger hover:bg-ink-danger text-ink-light block w-full rounded-lg px-6 py-2 text-lg font-semibold"
                :disabled="!cart.items.length"
                data-testid="clear"
                @click="clearCart"
              >
                Clear the cart
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="mt-12 flex px-8">
        <div class="grid w-full grid-cols-2 gap-4">
          <button
            class="text-ink-light block w-full rounded-lg bg-green-600 px-6 py-2 text-lg font-semibold hover:bg-green-500"
            @click="openModal"
          >
            {{ $t('changeLanguage') }}
          </button>
          <button
            class="bg-ink-primary -darker text-ink-light block w-full rounded-lg px-6 py-2 text-lg font-semibold"
            @click="isOpen = true"
          >
            Bottom Sheet
          </button>
        </div>
      </div>
      <div class="mt-12 flex px-8">
        <div class="grid w-full grid-cols-2 gap-4">
          <button
            class="text-ink-light block w-full rounded-lg bg-green-600 px-6 py-2 text-lg font-semibold hover:bg-green-500"
            @click="showGlobalModalMaintenance"
          >
            Show Global Modal Maintenance
          </button>
          <button
            class="bg-ink-primary -darker text-ink-light block w-full rounded-lg px-6 py-2 text-lg font-semibold"
            @click="showGlobalNotAuthenticated"
          >
            Show Global Not Authenticated
          </button>
        </div>
      </div>
      <div class="mt-12 flex px-8">
        <div class="grid w-full grid-cols-2 gap-4">
          <button
            class="text-ink-light block w-full rounded-lg bg-green-600 px-6 py-2 text-lg font-semibold hover:bg-green-500"
            @click="showGlobalMesageAlert"
          >
            Show Global Message Alert
          </button>
        </div>
      </div>

      <div class="mt-10 flex w-full flex-col px-4">
        <!-- <vs-button vs-type="filled">Hello World</vs-button> -->
        <button
          class="vs-button-success vs-button-filled vs-button mt-4 inline rounded-lg py-4 text-base font-semibold"
        >
          Button Success
        </button>
        <button
          class="vs-button-primary vs-button-filled vs-button mt-4 inline rounded-lg text-base font-semibold"
        >
          Button Primary Color Change
        </button>
        <button
          class="vs-button-warning vs-button-border vs-button mt-4 inline rounded-lg text-base font-semibold"
        >
          Button Warning
        </button>

        <vs-button
          class="mt-4 w-full"
          type="border"
          color="primary"
          icon-pack="feather"
          >Vuesax Primary Color Change</vs-button
        >

        <vs-button
          class="mt-4 w-full"
          type="filled"
          color="success"
          icon-pack="feather"
          >Vuesax success</vs-button
        >

        <vs-button
          class="mt-4 w-full"
          type="filled"
          color="danger"
          icon-pack="feather"
          >Vuesax danger</vs-button
        >

        <vs-button
          class="mt-4 w-full"
          type="filled"
          color="warning"
          icon-pack="feather"
          >Vuesax warning</vs-button
        >
      </div>
      <div class="mt-12 flex px-8">
        <Listbox v-model="selectedPerson" class="w-full">
          <div class="relative mt-1">
            <ListboxButton
              class="bg-ink-light relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <span class="block truncate">{{ selectedPerson.name }}</span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <SelectorIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="bg-ink-light absolute mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-for="person in people"
                  v-slot="{ active, selected }"
                  :key="person.name"
                  :value="person"
                  as="template"
                >
                  <li
                    :class="[
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-10 pr-4',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]"
                      >{{ person.name }}</span
                    >
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
      <div class="mt-12 flex px-8">
        <label class="block w-full">
          <span class="typo__label text-gray-700"
            >Select with search. using vue multiselect</span
          >

          <Multiselect
            v-model="value"
            class="focus:border-ink-primary focus:ring-primary-darker w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50"
            placeholder="Select one"
            :searchable="true"
            :close-on-select="false"
            :options="multiSelectOptions"
          />
          <pre class="language-json"><code>{{ value  }}</code></pre>
        </label>
      </div>

      <div class="mt-20 flex flex-col px-8">
        <HomeIcon class="mb-1 inline-block h-6 w-6" />
        <HomeIcon class="mb-1 inline-block h-6 w-6" />
        <HomeIcon class="mb-1 inline-block h-6 w-6" />
        <br /><br />

        <span class="mb-4 text-lg font-bold"
          >Route Meta Title: {{ $route.meta.title }}</span
        >

        <span>{{ $n(10000, 'currency', { useGrouping: false }) }}</span>
        <span>{{ $n(10000, 'currency') }}</span>
        <span>{{ $n(10000, 'currency', { notation: 'compact' }) }}</span>
        <span>{{ $n(10000, 'currencyNoCents') }}</span>

        <Currency
          total-in-string="450000"
          :with-prefix="false"
          leading-text="Points"
          wrap-class="mt-10"
          text-class="text-gray-400"
        />
        <br />

        <span class="text-ink-danger">{{ formattedDate }}</span>

        <br />
      </div>
      <div class="mt-20 flex">
        <swiper
          :modules="modules"
          :class="deviceWidth"
          :loop="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :slides-per-view="1"
          :pagination="{ clickable: true }"
          :scrollbar="{ draggable: true }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide>
            <img
              src="@/assets/image/icon.png"
              class="h-48 w-full cursor-pointer object-cover"
            />
          </swiper-slide>
          <swiper-slide
            ><img
              src="@/assets/image/icon.png"
              class="h-48 w-full cursor-pointer object-cover"
          /></swiper-slide>
          <swiper-slide
            ><img
              src="@/assets/image/icon.png"
              class="h-48 w-full cursor-pointer object-cover"
          /></swiper-slide>
        </swiper>
      </div>
      <div class="mt-20 flex">
        <swiper
          :modules="modulesFree"
          :class="deviceWidth"
          :slides-per-view="2.5"
          :free-mode="true"
          :space-between="10"
          :pagination="{ clickable: true }"
          :scrollbar="{ draggable: true }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide class="ml-4">
            <img
              src="@/assets/image/placeholder.png"
              class="h-20 w-full cursor-pointer rounded-lg object-cover"
            />
          </swiper-slide>
          <swiper-slide
            ><img
              src="@/assets/image/placeholder.png"
              class="h-20 w-full cursor-pointer rounded-lg object-cover"
          /></swiper-slide>
          <swiper-slide
            ><img
              src="@/assets/image/placeholder.png"
              class="h-20 w-full cursor-pointer rounded-lg object-cover"
          /></swiper-slide>
          <swiper-slide>
            <img
              src="@/assets/image/placeholder.png"
              class="h-20 w-full cursor-pointer rounded-lg object-cover"
            />
          </swiper-slide>
          <swiper-slide
            ><img
              src="@/assets/image/placeholder.png"
              class="h-20 w-full cursor-pointer rounded-lg object-cover"
          /></swiper-slide>
          <swiper-slide
            ><img
              src="@/assets/image/placeholder.png"
              class="h-20 w-full cursor-pointer rounded-lg object-cover"
          /></swiper-slide>
        </swiper>
      </div>

      <div class="mt-20 flex flex-col px-8">
        <card-shimmer :is-loading="true" class="mt-4" />
        <paragraph-shimmer
          :is-loading="true"
          :lines="4"
          :random-size="true"
          class="mt-4"
        />
        <free-style-shimmer
          :is-loading="true"
          height="100px"
          width="100px"
          border-radius="50px"
          color="#bdbdbd"
          class="mt-4"
        />
      </div>
    </div>

    <Sheet
      v-model:visible="isOpen"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="40%"
      max-height="70%"
    >
      Hello I am inside of sheet :) Try to slide down or click outside. Hello I
      am inside of sheet :) Try to slide down or click outside. Hello I am
      inside of sheet :) Try to slide down or click outside. Hello I am inside
      of sheet :) Try to slide down or click outside. Hello I am inside of sheet
      :) Try to slide down or click outside. Hello I am inside of sheet :) Try
      to slide down or click outside. Hello I am inside of sheet :) Try to slide
      down or click outside. Hello I am inside of sheet :) Try to slide down or
      click outside. Hello I am inside of sheet :) Try to slide down or click
      outside. Hello I am inside of sheet :) Try to slide down or click outside.
      Hello I am inside of sheet :) Try to slide down or click outside. Hello I
      am inside of sheet :) Try to slide down or click outside. Hello I am
      inside of sheet :) Try to slide down or click outside. Hello I am inside
      of sheet :) Try to slide down or click outside. Hello I am inside of sheet
      :) Try to slide down or click outside. Hello I am inside of sheet :) Try
      to slide down or click outside. Hello I am inside of sheet :) Try to slide
      down or click outside. Hello I am inside of sheet :) Try to slide down or
      click outside. Hello I am inside of sheet :) Try to slide down or click
      outside. Hello I am inside of sheet :) Try to slide down or click outside.
      Hello I am inside of sheet :) Try to slide down or click outside. Hello I
      am inside of sheet :) Try to slide down or click outside. Hello I am
      inside of sheet :) Try to slide down or click outside. Hello I am inside
      of sheet :) Try to slide down or click outside. Hello I am inside of sheet
      :) Try to slide down or click outside. Hello I am inside of sheet :) Try
      to slide down or click outside. Hello I am inside of sheet :) Try to slide
      down or click outside. Hello I am inside of sheet :) Try to slide down or
      click outside. Hello I am inside of sheet :) Try to slide down or click
      outside. Hello I am inside of sheet :) Try to slide down or click outside.
      Hello I am inside of sheet :) Try to slide down or click outside. Hello I
      am inside of sheet :) Try to slide down or click outside. Hello I am
      inside of sheet :) Try to slide down or click outside. Hello I am inside
      of sheet :) Try to slide down or click outside. Hello I am inside of sheet
      :) Try to slide down or click outside. Hello I am inside of sheet :) Try
      to slide down or click outside. Hello I am inside of sheet :) Try to slide
      down or click outside. Hello I am inside of sheet :) Try to slide down or
      click outside. Hello I am inside of sheet :) Try to slide down or click
      outside. Hello I am inside of sheet :) Try to slide down or click outside.
    </Sheet>

    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="bg-ink-light my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  {{ $t('changeLanguage') }}
                </DialogTitle>
                <div class="mt-2">
                  <Listbox v-model="locale">
                    <div class="relative mt-1">
                      <ListboxButton
                        class="bg-ink-light relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                      >
                        <span class="block truncate">{{ locale }}</span>
                        <span
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                        >
                          <SelectorIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>

                      <transition
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                      >
                        <ListboxOptions
                          class="bg-ink-light absolute mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                          <ListboxOption value="id" as="template">
                            <li
                              :class="[
                                'text-gray-900',
                                'relative cursor-default select-none py-2 pl-10 pr-4',
                              ]"
                            >
                              <span :class="['font-normal', 'block truncate']"
                                >Indonesia</span
                              >
                            </li>
                          </ListboxOption>
                          <ListboxOption value="en" as="template">
                            <li
                              :class="[
                                'text-gray-900',
                                'relative cursor-default select-none py-2 pl-10 pr-4',
                              ]"
                            >
                              <span :class="['font-normal', 'block truncate']"
                                >English</span
                              >
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>

                <div class="mt-4">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    @click="closeModal"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { defineComponent, ref, inject, defineEmits, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Sheet } from 'bottom-sheet-vue3'
// import { CardShimmer, ParagraphShimmer, FreeStyleShimmer } from 'shimmer'

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue'

import {
  Pagination,
  Mousewheel,
  Autoplay,
  FreeMode,
  Lazy,
  Scrollbar,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

import Multiselect from '@vueform/multiselect'

import { useUserStore } from '@/store/pinia/user'
import { useCartStore } from '@/store/pinia/cart'

import Currency from '@/components/currency/Currency.vue'
import ButtonRepo from '@/components/button/ButtonRepo.vue'

import HomeIcon from '@/assets/svg/home.svg'

export default defineComponent({
  components: {
    Sheet,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    Listbox,
    Swiper,
    SwiperSlide,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
    Multiselect,
    Currency,
    ButtonRepo,
    HomeIcon,
    // CardShimmer,
    // ParagraphShimmer,
    // FreeStyleShimmer,
  },
  inject: ['actionBack', 'deviceWidth', 'defaultInstitute'],
  emits: [
    'showGlobalModalMaintenance',
    'showGlobalNotAuthenticated',
    'showToast',
  ],
  setup(props, { emit }) {
    const dayjs = inject('dayjs')

    // inject: ['actionBack', 'deviceWidth', 'dayjs', 'defaultInstitute'],

    // const actionBack = actionBack;
    // modules: [Autoplay, Pagination, Scrollbar, Mousewheel, Lazy, FreeMode],
    const modules = [Autoplay, Pagination]
    const modulesFree = [Scrollbar, Mousewheel, Lazy, FreeMode]

    const user = useUserStore()
    const cart = useCartStore()

    const multiSelectOptions = [
      { value: 'Vue.js', label: 'JavaScript' },
      { value: 'Rails', label: 'Ruby' },
      { value: 'Sinatra', label: 'Ruby' },
      { value: 'Laravel', label: 'PHP' },
      { value: 'Phoenix', label: 'Elixir' },
    ]

    const people = [
      { name: 'Wade Cooper' },
      { name: 'Arlene Mccoy' },
      { name: 'Devon Webb' },
      { name: 'Tom Cook' },
      { name: 'Tanya Fox' },
      { name: 'Hellen Schmidt' },
    ]

    window.stores = { user, cart }

    onMounted(() => {
      formattedDate.value = dayjs(new Date()).format('DD MMMM')
      selectedPerson.value = people[0]
    })

    let formattedDate = ref(new Date())
    let selectedPerson = ref({})
    const value = ref(null)
    const itemName = ref('')
    const isOpen = ref(false)
    const isModalOpen = ref(false)

    const nameWithLang = ({ name, language }) => {
      return `${name} — [${language}]`
    }

    const { locale } = useI18n()

    const addItemToCart = () => {
      if (!itemName.value) return
      cart.addItem(itemName.value)
      itemName.value = ''
    }

    const clearCart = () => {
      if (window.confirm('Are you sure you want to clear the cart?')) {
        cart.rawItems = []
      }
    }

    const buy = async () => {
      const n = await cart.purchaseItems()

      console.log(`Bought ${n} items`)

      cart.rawItems = []
    }

    const setIsModalOpen = (value) => {
      isModalOpen.value = value
    }

    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }

    const closeModal = () => {
      isModalOpen.value = false
    }
    const openModal = () => {
      isModalOpen.value = true
    }

    // const emit = defineEmits([
    //   'showGlobalModalMaintenance',
    //   'showGlobalNotAuthenticated',
    // ])

    const showGlobalModalMaintenance = () => {
      emit('showGlobalModalMaintenance', true)
    }

    const showGlobalNotAuthenticated = () => {
      emit('showGlobalNotAuthenticated', true)
    }

    const showGlobalMesageAlert = () => {
      emit('show-global-message-alert', {
        show: true,
        message: 'message',
        type: 'error',
      })
    }

    return {
      modules,
      modulesFree,
      formattedDate,
      value,
      selectedPerson,
      itemName,
      isOpen,
      isModalOpen,
      nameWithLang,
      user,
      cart,
      multiSelectOptions,
      people,
      locale,
      addItemToCart,
      clearCart,
      buy,
      setIsModalOpen,
      onSwiper,
      onSlideChange,
      closeModal,
      openModal,
      showGlobalModalMaintenance,
      showGlobalNotAuthenticated,
      showGlobalMesageAlert,
    }
  },
})
</script>
