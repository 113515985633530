<template>
  <div>
    <metainfo>
      <template #title="{ content }">{{
        content ? `${content}` : `SITE_NAME`
      }}</template>
    </metainfo>
    <AppLayout>
      <ModalBasic
        v-if="isAuthenticated"
        :title="$t('information')"
        class="py-8"
        :show-close="false"
        :can-close="false"
      >
        <p class="text-ink-danger">
          {{ $t('you_are_not_logged_in') }}
        </p>
      </ModalBasic>

      <ModalBasic
        v-if="showModalMaintenance"
        :title="$t('information')"
        class="py-8"
        :show-close="false"
        :can-close="false"
        :show="showModalMaintenance"
      >
        <div class="flex flex-wrap justify-center text-center">
          <div class="text-ink-primary mx-auto my-4 flex w-full justify-center">
            <MaintenanceIcon class="h-12 w-12" />
          </div>
          <div class="mb-6 w-full">
            <p class="text-2xl font-medium">
              {{ modalMaintenanceTitle }}
            </p>
          </div>
          <div class="w-full px-20 text-gray-500">
            <p>
              {{ modalMaintenanceDesc }}
            </p>
          </div>
          <div class="pt-5">
            <router-link
              to="/"
              class="bg-ink-primary text-ink-light inline-flex items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium leading-6 opacity-90 transition duration-150 ease-in-out hover:opacity-100 focus:outline-none"
              @click="showModalMaintenance = false"
            >
              {{ $t('back_to_home') }}
            </router-link>
          </div>
        </div>
      </ModalBasic>

      <Toast
        v-if="messageAlertActive"
        :type="messageAlertType"
        :message="messageAlertMessage"
        :use-html="messageAlertUseHtml"
        :position="messageAlertPosition"
      />
      <router-view
        :key="$route.fullPath"
        v-slot="{ Component }"
        @show-global-modal-maintenance="showGlobalModalMaintenance"
        @show-global-not-authenticated="showGlobalNotAuthenticated"
        @show-toast="showGlobalMessageAlert"
        @show-loading-indicator="showGlobalLoadingIndicator"
      >
        <transition name="moveInUp">
          <component :is="Component" />
        </transition>
      </router-view>
    </AppLayout>
    <LoadingIndicator v-if="isLoading" />
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import globalMixin from '@/mixins/global.js'
import AppLayout from '@/layouts/layout.vue'
import LoadingIndicator from '@/components/loader/LoadingIndicator.vue'
import ModalBasic from '@/components/modal/ModalBasic.vue'
import Toast from '@/components/alert/Toast.vue'

import MaintenanceIcon from '@/assets/svg/maintenance.svg'

export default {
  name: 'App',
  components: {
    AppLayout,
    LoadingIndicator,
    MaintenanceIcon,
    ModalBasic,
    Toast,
  },
  mixins: [globalMixin],

  provide() {
    return {
      message: computed(() => this.message),
      defaultInstitute: computed(() => this.defaultInstitute),
      actionBack: (
        to = '',
        params = {},
        backIndex = -1,
        last = '',
        forceTo = false
      ) => {
        if (to) {
          if (history.state.back !== '' && !forceTo) {
            this.$router.go(backIndex)
          } else {
            const slugParams = { slug: this.$route.params.slug }
            let routerParams = { ...slugParams }
            if (params) routerParams = { ...slugParams, ...params }
            let queryParams = {
              lang: this.$route.query.lang,
            }
            if (last) queryParams = { ...queryParams, last: last }
            this.$router.push({
              name: to,
              params: routerParams,
              query: queryParams,
            })
          }
        } else if (history.state.back !== '') {
          this.$router.go(backIndex)
        } else {
          this.$router.push({
            name: 'home',
            params: { slug: this.$route.params.slug },
            query: {
              lang: this.$route.query.lang,
            },
          })
        }
      },
    }
  },

  setup() {
    let isLoading = ref(false)

    const { t, n } = useI18n()

    useMeta({
      title: 'Warung Prabowo',
      htmlAttrs: { lang: 'en', amp: true },
    })

    const scrollTop = ref('0')

    let isAuthenticated = ref(false)
    let showModalMaintenance = ref(false)
    let modalMaintenanceTitle = ref('')
    let modalMaintenanceDesc = ref('')

    const showGlobalModalMaintenance = (val) => {
      showModalMaintenance.value = val.show
      modalMaintenanceTitle.value = val.title
      modalMaintenanceDesc.value = val.desc
    }

    const showGlobalNotAuthenticated = (val) => {
      isAuthenticated.value = val
    }

    const showGlobalLoadingIndicator = (state) => {
      isLoading.value = state
    }

    let messageAlertActive = ref(false)
    let messageAlertMessage = ref(null)
    let messageAlertType = ref(null)
    let messageAlertUseHtml = ref(false)
    let messageAlertPosition = ref('bottom')

    const showGlobalMessageAlert = (alert) => {
      if (alert.useHtml !== undefined && alert.useHtml !== undefined) {
        messageAlertUseHtml.value = alert.useHtml
      }
      if (alert.position !== undefined && alert.position !== undefined) {
        messageAlertPosition.value = alert.position
      }
      if (alert.type !== undefined && alert.type !== undefined) {
        messageAlertType.value = alert.type
      }
      messageAlertMessage.value = alert.message
      messageAlertActive.value = alert.show
      setTimeout(() => {
        messageAlertActive.value = false
      }, 2000)
    }

    // showGlobalModalMaintenance(isShowed) {
    //   this.showModalMaintenance = isShowed
    // },

    return {
      t,
      n,
      scrollTop,
      isLoading,
      isAuthenticated,
      showModalMaintenance,
      modalMaintenanceTitle,
      modalMaintenanceDesc,
      messageAlertActive,
      messageAlertMessage,
      messageAlertType,
      messageAlertUseHtml,
      messageAlertPosition,
      // messageAlert,
      showGlobalModalMaintenance,
      showGlobalMessageAlert,
      showGlobalNotAuthenticated,
      showGlobalLoadingIndicator,
    }
  },
  data() {
    return {
      message: 'hello',
      defaultInstitute: 'cinere-garden',
    }
  },
}
</script>
