<template>
  <div
    class="flex-center h flex justify-start transition duration-500 hover:scale-105"
  >
    <div class="flex">
      <slot></slot>
    </div>
    <div class="flex flex-col pl-4">
      <p class="mt-1 text-sm text-white">{{ title }}</p>
      <p class="mt-4 text-xs leading-6 text-gray-400">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardTwo',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    click() {
      this.$emit('onClick')
    },
  },
}
</script>
