<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col-reverse px-2 pb-20 pt-10 md:flex-row lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div
        class="container mt-10 flex w-full items-center px-12 text-center md:mt-0 md:w-1/3 lg:px-20"
      >
        <img src="@/assets/image/logo.png" class="w-full" />
      </div>
      <div
        class="flex w-full flex-col justify-center px-4 md:w-2/3 md:px-12 lg:px-20"
      >
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          PLATFORM
        </p>

        <p class="md:text-md mt-4 text-xl text-black md:text-3xl">
          Warung Prabowo Featuring Platform
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-32"></div>

        <div class="mt-10 flex w-full cursor-pointer">
          <a href="#content-begin" class="flex">
            <p class="text-kira-primary text-xs tracking-widest md:text-sm">
              Let's Find Out
            </p>
            <ArrowBottom class="text-kira-primary ml-4 h-4" />
          </a>
        </div>
      </div>
    </div>

    <div
      id="content-begin"
      class="container flex flex-col px-2 pb-20 pt-10 md:flex-row lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div
        class="flex w-full flex-col justify-center px-4 md:w-1/2 md:px-12 lg:px-20"
      >
        <div
          class="flex-center mb-10 flex flex-col justify-start transition duration-500 hover:scale-105"
        >
          <div class="mb-4 flex">
            <p class="text-sm font-black text-black md:text-xl">
              Warung Prabowo Control Center
            </p>
          </div>
          <div
            v-for="item in controlCenter"
            :key="item.id"
            class="mt-4 flex flex-row"
          >
            <div class="flex pr-2">
              <CircleCheck class="text-kira-success h-6" />
            </div>
            <div class="flex items-start">
              <p class="text-xs leading-6 text-gray-400">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container flex w-full items-center justify-center md:w-1/2">
        <div class="relative flex items-center">
          <CircleOne class="absolute w-full" />
          <div class="z-20 flex w-full flex-col px-2 md:px-0">
            <img src="@/assets/image/logo.png" class="w-full" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="container flex flex-col-reverse px-2 pb-20 pt-10 md:flex-row lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="container flex w-full items-center md:w-1/2 md:justify-start">
        <div class="relative flex w-full items-center">
          <CircleTwo class="absolute w-full" />
          <div class="z-20 flex w-full flex-col px-4 md:px-12 lg:px-20">
            <img src="@/assets/image/logo.png" class="w-full" />
          </div>
        </div>
      </div>
      <div
        class="flex w-full flex-col justify-center px-4 md:w-1/2 md:px-12 lg:px-20"
      >
        <div
          class="flex-center mb-10 flex flex-col justify-start transition duration-500 hover:scale-105"
        >
          <div class="mb-4 flex">
            <p class="text-sm font-black text-black md:text-xl">
              The Warung Prabowo Store App and The Warung Prabowo Website
            </p>
          </div>
          <div
            v-for="item in storeApp"
            :key="item.id"
            class="mt-4 flex flex-row"
          >
            <div class="flex pr-2">
              <CircleCheck class="text-kira-success h-6" />
            </div>
            <div class="flex items-start">
              <p class="text-xs leading-6 text-gray-400">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container flex flex-col px-2 pb-20 pt-10 md:flex-row lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div
        class="flex w-full flex-col justify-center px-4 md:w-1/2 md:px-12 lg:px-20"
      >
        <div
          class="flex-center mb-10 flex flex-col justify-start transition duration-500 hover:scale-105"
        >
          <div class="mb-4 flex">
            <p class="text-sm font-black text-black md:text-xl">
              The Warung Prabowo Home Delivery App
            </p>
          </div>
          <div
            v-for="item in homeApp"
            :key="item.id"
            class="mt-4 flex flex-row"
          >
            <div class="flex pr-2">
              <CircleCheck class="text-kira-success h-6" />
            </div>
            <div class="flex items-start">
              <p class="text-xs leading-6 text-gray-400">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container flex w-full items-center md:w-1/2 md:justify-start">
        <div class="relative flex w-full items-center">
          <CircleThree class="absolute w-full" />
          <div class="z-20 flex w-full flex-col px-4 md:px-12 lg:px-20">
            <img src="@/assets/image/logo.png" class="w-full" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="container flex flex-row px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informations" :key="item.id" class="flex w-full">
          <CardThree :title="item.title" :description="item.description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import ArrowBottom from '@/assets/svg/arrow-bottom.svg'
import CircleCheck from '@/assets/svg/circle-check.svg'
import CircleOne from '@/assets/svg/illustration/circle-1.svg'
import CircleTwo from '@/assets/svg/illustration/circle-2.svg'
import CircleThree from '@/assets/svg/illustration/circle-3.svg'
import OfferUser from '@/assets/svg/offers/user.svg'

export default {
  name: 'ServiceSolutionForSupplyChainPage',
  components: {
    ArrowBottom,
    CircleCheck,
    CircleOne,
    CircleTwo,
    CircleThree,
    CardTwo,
    CardThree,
    OfferUser,
  },
  mixins: [globalMixin],
  data: () => ({
    controlCenter: [
      {
        id: 1,
        description:
          'Data platform for FMCG companies and distributors providing valuable insight into data collected by Warung Prabowo branded apps.',
      },
      {
        id: 2,
        description: 'Subscription based pricing.',
      },
      {
        id: 3,
        description: 'Development on going',
      },
      {
        id: 4,
        description: 'Project launch : Q4 2022',
      },
    ],
    storeApp: [
      {
        id: 1,
        description:
          'An App to manage your ‘warungs’, so you can monitor your sales and increase your profit!',
      },
      {
        id: 2,
        description: 'Free to use',
      },
      {
        id: 3,
        description: 'Development on going',
      },
      {
        id: 4,
        description: 'Project launch : Q4 2022',
      },
    ],
    homeApp: [
      {
        id: 1,
        description: 'Mobile App and home delivery platform for Consumers.',
      },
      {
        id: 2,
        description: 'Free to use.',
      },
      {
        id: 3,
        description: 'Planned to start development in H2 2022',
      },
      {
        id: 4,
        description: 'Projected launch in October 2022',
      },
    ],
    informations: [
      {
        id: 1,
        title: 'Warung Prabowo App & Network',
        description:
          'Warung Prabowo is the core foundation that brings the power of modern digital technology into the hands of mico retailers around the globe.  This easy to use yet sophisticated application powers significant benefits for the micro-retail owners and provides the core data that is then available in real-time to you.',
      },
      {
        id: 2,
        title: 'Warung Prabowo Community',
        description:
          'The Warung Prabowo Community is what links the individual micro-retailers together and allows you to conduct almost instantaneous surveys which will be backed up by sales data, to push experiments and obtain real-time feedback on their efficacy and to analyze sentiment on brands and products at any level of geography you desire from a global view down to as little as 500m2',
      },
      {
        id: 3,
        title: 'Warung Prabowo Data Platform',
        description:
          'This is where the magic happens.  By collecting data in real-time from the Warung Prabowo platform we can offer the most complete data, trends, insights and predictive analytics near instantaneously.  Take advantage of our comprehensive online analytics or access our data from your analytics applications to gain greater marketing, sales, and supply chain insights than you imagined were possible to drive breakthrough revenue and profitability for your organization',
      },
    ],
  }),
  methods: {},
}
</script>
