<template>
  <section id="content" class="flex flex-col bg-slate-900">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="mb-10 md:mb-20 lg:mb-32 flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          AYO BERTEMU
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-white md:text-2xl">
          Tim Luar Biasa Kami
        </p>

        <p class="mt-4 text-xs lg:text-sm text-gray-400">
          Berkenalan dengan orang-orang yang membantu menciptakan Warung Prabowo sebagai sebuah perusahaan
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-40"></div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap md:px-6 md:flex-row lg:px-32">
        <div
          v-for="item in team"
          :key="item.id"
          class="flex w-full p-2 md:w-1/2"
        >
          <CardFour
            :title="item.name"
            :subtitle="item.job"
            :linkedin="item.linkedin"
          >
            <!-- <img
              v-if="item.avatar === 'rinaldi'"
              src="@/assets/image/team/rinaldi.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'idin'"
              src="@/assets/image/team/idin.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'rubby'"
              src="@/assets/image/team/rubby.png"
              class="w-24 rounded-lg"
            /> -->
          </CardFour>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardFour from '@/components/card/CardFour.vue'

export default {
  name: 'ServiceSolutionForMarketingPage',
  components: {
    CardFour,
  },
  mixins: [globalMixin],
  data: () => ({
    team: [
      {
        id: 1,
        name: 'Rinaldi Saputra',
        job: 'Chief Executive Officer',
        avatar: 'rinaldi',
        linkedin: 'rinaldi-saputra-6611a3112'
        // description:
        //   '6 Tahun berkarir di dunia distribusi dan retail memiliki pengalaman sebagai GM Operations & Distribution at DLS Distributor (Kino Group distributor) dan COO at Tomura Retail (Part of Kino Group).',
      },
      {
        id: 2,
        name: 'Ahmad Muhyidin',
        job: 'Director IT & Supply Chain',
        avatar: 'idin',
        linkedin: 'idindev'
        // description:
        //   '5 Tahun berkarir di dunia Startup Technology & Digital konsultan sebagai Direktur Konsultan yang memiliki ratusan klien dari the financial industry & supply chain manajemen.',
      },
      {
        id: 3,
        name: 'Rubby Maulana Christy',
        job: 'Director Sales & Marketing',
        avatar: 'rubby',
        linkedin: 'rubby-maulana-christy-440625157'
        // description: '6 Tahun berkarir di dunia startup pembayaran retail bersama Buku Warung yang membantu sistem pembayaran untuk UMKM /warung dan melakukan ribuan digitalisasi warung',
      },
    ],
  }),
  methods: {},
}
</script>
