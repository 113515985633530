import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({
  isOpen: true,
})

// getters
const getters = {
  lastInstituteGetter(state) {
    return localStorage.getItem('warungprabowo.lastInstitute') || null
  },
  isOpen(state) {
    return state.isOpen
  },
}

// actions
const actions = {
  setLastInstitute({ state, commit }, payload) {
    commit('setLastInstitute', payload)
  },
  resetLastInstitute({ state, commit }) {
    commit('resetLastInstitute')
  },
  setIsOpen({ state, commit }, payload) {
    commit('setIsOpen', payload)
  },
  resetIsOpen({ state, commit }) {
    commit('resetIsOpen')
  },
  institutionList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}institutes/?search=${payload.search}`)
        .then((response) => {
          const responseData = response.data
          function mappingInstitutes(item) {
            return { value: item.attributes.slug, label: item.attributes.name }
          }
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData.data.map(mappingInstitutes),
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  institutionDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}institute/${payload.id}/`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  applyIstitute({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}institute/apply/?use_serializer=0`, {
          id: payload.id,
          is_default: payload.is_default,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateInstitute({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}current-institute-id/`, payload)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  setLastInstitute(state, val) {
    localStorage.setItem('warungprabowo.lastInstitute', val)
  },
  resetLastInstitute(state) {
    localStorage.setItem('warungprabowo.lastInstitute', '')
  },
  setIsOpen(state, val) {
    state.isOpen = val
  },
  resetIsOpen(state) {
    state.isOpen = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
