import axiosOrder, { routePathOrderAPI } from '@/plugins/axios-order.js'

// initial state
const state = () => ({
  checkoutData: {},
})

// getters
const getters = {
  checkoutDataGetter(state) {
    return localStorage.getItem('warungprabowo.checkoutData') !== null
      ? JSON.parse(localStorage.getItem('warungprabowo.checkoutData'))
      : {}
    // return state.checkoutData
  },
  guestIdGetter() {
    return localStorage.getItem('warungprabowo.guestId') || null
  },
}

// actions
const actions = {
  setCheckoutData({ state, commit }, payload) {
    commit('SET_CHECKOUT_DATA', payload)
  },
  resetCheckoutData({ state, commit }, payload) {
    commit('RESET_CHECKOUT_DATA')
  },
  removeGuest({ state, commit }, payload) {
    commit('REMOVE_GUEST_ID')
  },
  fetchGuest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOrder
        .get(`${routePathOrderAPI()}fetch?instituteId=${payload.instituteId}`)
        .then((response) => {
          const responseData = response.data
          if (responseData.statusCode === 200) {
            const token = responseData.data
            // SET NEW TOKEN
            localStorage.setItem('warungprabowo.guestId', token.g_id)

            resolve({
              status: response.status,
              message: 'success',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_CHECKOUT_DATA(state, checkoutData) {
    localStorage.setItem('warungprabowo.checkoutData', JSON.stringify(checkoutData))
    // state.checkoutData = checkoutData
  },
  RESET_CHECKOUT_DATA(state) {
    localStorage.removeItem('warungprabowo.checkoutData')
    // state.checkoutData = {}
  },
  REMOVE_GUEST_ID(state) {
    localStorage.removeItem('warungprabowo.guestId')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
