<template>
  <section id="content" class="flex h-screen bg-slate-900">
    <div class="flex w-full place-items-center justify-center">
      <div class="absolute inset-x-0">
        <div
          aria-hidden="true"
          class="grid grid-cols-2 -space-x-52 opacity-50 dark:opacity-60 2xl:mx-auto"
        >
          <div
            class="h-60 bg-gradient-to-br from-primary to-purple-400 blur-3xl dark:from-blue-700"
          ></div>
          <div
            class="h-60 bg-gradient-to-r from-primary to-purple-400 blur-3xl dark:from-blue-700"
          ></div>
        </div>
      </div>
      <div class="z-10 flex w-full flex-col items-center">
        <img src="@/assets/image/icon.png" class="h-20 rounded-lg md:h-24" />
        <p class="text-md mt-12 text-gray-400 md:text-2xl">Warung Prabowo</p>
        <div class="flex space-y-0">
          <p class="text-2xl text-white lg:text-4xl">Coming Soon</p>
        </div>
        <div class="flex w-full flex-col items-center justify-center">
          <div class="relative flex items-center justify-center">
            <CircleLines />
            <div class="absolute items-center">
              <Button
                title="Mari Cari Tahu"
                @click="
                  $router.push({
                    name: 'contact-us',
                    params: {
                      slug: $route.params.slug,
                    },
                    query: {
                      lang: $route.query.lang,
                    },
                  })
                "
              >
                <ArrowRightIcon class="ml-2 h-3 text-white" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref } from 'vue'
import { useMeta } from 'vue-meta'
import globalMixin from '@/mixins/global.js'
import Button from '@/components/button/Button.vue'

// Svgs
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import CircleLines from '@/assets/svg/circle-lines.svg'
import Logo from '@/assets/image/logo.png'

export default {
  name: 'HomePage',
  components: {
    Button,
    ArrowRightIcon,
    CircleLines,
    Logo,
  },
  mixins: [globalMixin],
  setup() {
    const description = ref('Berjuang mensejahterakan ekonomi warung dan warga')

    useMeta({
      title: 'Warung Prabowo',
      htmlAttrs: { lang: 'id', amp: true },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description.value,
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Warung Prabowo',
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description.value,
        },
        {
          vmid: 'og:image:alt',
          property: 'og:image:alt',
          content: description.value,
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://warungprabowo.com',
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: '@/assets/image/logo.png',
        },
      ],
    })
  },
  data: () => ({}),
  methods: {},
}
</script>
