<template>
  <!-- <div class="mb-10 flex overflow-y-auto lg:mb-0">
    <div class="flex w-full flex-col justify-center">
      <div class="mb-8 flex flex-col">
        <div class="mb-6 flex w-full justify-center">
          <p class="text-kira-primary text-xl font-semibold">Jadwalkan Demo</p>
        </div>
        <div class="mb-1 hidden w-full justify-center lg:flex">
          <p class="text-center text-sm text-gray-500">
            Dapatkan konsultasi dan demo gratis, kirimkan email Anda kepada kami
          </p>
        </div>
        <div class="hidden w-full justify-center lg:flex">
          <p class="text-center text-sm text-gray-500">
            dan kami akan menghubungi Anda secara langsung.
          </p>
        </div>
        <div class="flex w-full justify-center px-4 lg:hidden">
          <p class="text-center text-sm text-gray-500">
            Dapatkan konsultasi dan demo gratis, kirimkan email Anda kepada kami dan kami akan menghubungi Anda secara langsung.
          </p>
        </div>
      </div>
      <div class="flex w-full justify-center">
        <div
          class="relative flex w-full flex-wrap items-stretch px-6 lg:w-2/3 lg:px-0 lg:w-1/3"
        >
          <input
            type="text"
            class="border-ink-primary relative h-12 w-px flex-auto flex-shrink flex-grow rounded rounded-r-none border px-3 leading-normal"
            placeholder="Masukkan email Anda disini..."
          />
          <div class="flex cursor-pointer">
            <span
              class="bg-slate-800 border-ink-primary whitespace-no-wrap flex items-center rounded rounded-l-none border border-l-0 px-5 text-sm leading-normal text-white"
              >Kirim</span
            >
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="flex w-full flex-col">
    <div class="bg-slate-800 w-full py-10">
      <div class="container mx-auto">
        <div
          class="mt-6 flex w-full flex-col lg:flex-row lg:items-center lg:justify-between"
        >
          <div
            class="mb-12 flex w-full flex-col justify-start self-start px-4 lg:mb-0 lg:w-1/4"
          >
            <div class="flex">
              <img src="@/assets/image/logo.png" class="h-12" />
              <!-- <LogoIcon class="h-10 text-white" /> -->
            </div>
            <div class="mt-6 flex flex-row">
              <div class="flex cursor-pointer">
                <InstagramIcon class="h-6 text-white" />
              </div>
              <div class="ml-6 flex cursor-pointer">
                <TwitterIcon class="h-6 text-white" />
              </div>
              <div class="ml-6 flex cursor-pointer">
                <FacebookIcon class="h-6 text-white" />
              </div>
            </div>
          </div>
          <div
            class="mb-12 flex w-full flex-col justify-start px-4 lg:mb-0 lg:w-2/4 md:flex-row md:justify-between"
          >
            <div class="mb-8 flex flex-col text-white lg:mb-0">
              <div class="flex">
                <p class="text-md font-semibold">Navigasi</p>
              </div>
              <div class="mt-4 flex cursor-pointer" @click="openRoute('home')">
                <p class="text-sm text-gray-50 hover:text-white">Beranda</p>
              </div>
              <!-- <div class="mt-4 flex cursor-pointer">
                <p class="text-sm text-gray-50 hover:text-white">Platform</p>
              </div> -->
              <div
                class="mt-4 flex cursor-pointer"
                @click="openRoute('partnership')"
              >
                <p class="text-sm text-gray-50 hover:text-white">Kemitraan</p>
              </div>
            </div>
            <div class="mb-8 flex flex-col text-white lg:mb-0">
              <div class="flex">
                <p class="text-md font-semibold">Tentang Kami</p>
              </div>
              <div
                class="mt-4 flex cursor-pointer"
                @click="openRoute('about-us-our-team')"
              >
                <p class="text-sm text-gray-50 hover:text-white">
                  Visi dan Misi kami
                </p>
              </div>
              <div
                class="mt-4 flex cursor-pointer"
                @click="openRoute('about-us-our-vision-mission')"
              >
                <p class="text-sm text-gray-50 hover:text-white">Tim Kami</p>
              </div>
              <div class="mt-4 flex cursor-pointer">
                <p class="text-sm text-gray-50 hover:text-white">Kontak Kami</p>
              </div>
            </div>
            <div class="flex flex-col text-white">
              <div class="flex">
                <p class="text-md font-semibold">Halaman Lainnya</p>
              </div>
              <div class="mt-4 flex cursor-pointer">
                <p class="text-sm text-gray-50 hover:text-white">Pusat Bantuan</p>
              </div>
              <div class="mt-4 flex cursor-pointer">
                <p class="text-sm text-gray-50 hover:text-white">FAQ</p>
              </div>
              <div class="mt-4 flex cursor-pointer">
                <p class="text-sm text-gray-50 hover:text-white">
                  Term Of Services
                </p>
              </div>
            </div>
          </div>
          <div class="flex w-full justify-start px-4 lg:w-1/4 lg:justify-end">
            <div class="flex flex-col text-white">
              <div class="flex">
                <p class="text-md font-semibold">Kontak Kami</p>
              </div>
              <div class="mt-4 flex flex-col">
                <p class="text-sm text-gray-50">PT. Karya Kreasi Asia</p>
                <p class="text-sm text-gray-50">
                  Kawasan 3 Bisnis Center Blok C3 - 15 & 16,
                </p>
                <p class="text-sm text-gray-50">
                  Jalan Lingkar Tanjung Pura, Karawang Barat,
                </p>
                <p class="text-sm text-gray-50">Karawang - Jawa Barat</p>
                <p class="text-sm text-gray-50">41361</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-20 flex w-full flex-col lg:mt-24 md:flex-row md:items-center"
        >
          <div class="mb-2 flex w-full justify-start px-4 lg:mb-0 lg:w-1/4">
            <p class="text-sm text-gray-50 hover:text-white">
              Copyright by warungprabowo.com
            </p>
          </div>
          <div
            class="mb-2 flex w-full flex-col justify-start px-4 lg:mb-0 lg:w-2/4 lg:flex-row lg:justify-center"
          >
            <p class="text-sm text-gray-50 hover:text-white">
              #warungprabowo #warungprabowoindonesia #mitrawarungprabowo
            </p>
          </div>
          <div class="flex w-full justify-start px-4 lg:w-1/4 lg:justify-end">
            <p class="text-sm text-gray-50 hover:text-white">
              PT. Karya Kreasi Asia |
              <span> {{ new Date().getFullYear() }} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import FooterIllustration from '@/assets/svg/footer.svg'
// import LogoIcon from '@/assets/svg/logo.svg'
import InstagramIcon from '@/assets/svg/socmed/instagram.svg'
import TwitterIcon from '@/assets/svg/socmed/twitter.svg'
import FacebookIcon from '@/assets/svg/socmed/facebook.svg'

export default {
  name: 'FooterDefault',
  components: {
    FooterIllustration,
    // LogoIcon,
    InstagramIcon,
    TwitterIcon,
    FacebookIcon,
  },
  mixins: [globalMixin],
  data: () => ({ showMenu: false, serviceOpen: false, aboutOpen: false }),
  methods: {},
}
</script>
