import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({
  finalPrice: 0,
  tableRow: '',
  isDineIn: true,
  servingTime: '',
})

// getters
const getters = {
  estimatedTimeGetter(state) {
    return localStorage.getItem('warungprabowo.estimatedTime') || null
  },
  finalPriceGetter(state) {
    return state.finalPrice || 0
  },
  tableRowGetter(state) {
    return state.tableRow || ''
  },
  orderTypeGetter(state) {
    return state.isDineIn
  },
  servingTimeGetter(state) {
    return state.servingTime || ''
  },
}

// actions
const actions = {
  setEstimatedTime({ state, commit }, payload) {
    commit('SET_ESTIMATED_TIME', payload)
  },
  setFinalPrice({ state, commit }, payload) {
    commit('SET_FINAL_PRICE', payload)
  },
  setTableRow({ state, commit }, payload) {
    commit('SET_TABLE_ROW', payload)
  },
  setOrderType({ state, commit }, payload) {
    commit('SET_ORDER_TYPE', payload)
  },
  setServingTime({ state, commit }, payload) {
    commit('SET_SERVING_TIME', payload)
  },
  resetTableRow({ state, commit }) {
    commit('SET_TABLE_ROW', '')
  },
  resetOrderType({ state, commit }) {
    commit('SET_ORDER_TYPE', true)
  },
  resetServingTime({ state, commit }) {
    commit('SET_SERVING_TIME', '')
  },
  checkoutProcess({ state, commit }, payload) {
    const formData = new FormData()
    formData.append('promo_id', payload.promoId)
    formData.append('cart_id', payload.cartId)
    formData.append('is_serving_now', payload.isServingNow)
    formData.append('serving_time', payload.servingTime)
    formData.append('channel_code', payload.channelCode)
    formData.append('payment_type', payload.paymentType)
    formData.append('phone_number', payload.phoneNumber)
    formData.append('table', payload.table)
    formData.append('order_type', payload.orderType)
    formData.append('unique_code', payload.uniqueCode)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}checkout/process/`, formData, config)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeTableRow({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}order/update-table/${payload.orderId}`, {
          table: payload.table,
        })
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_ESTIMATED_TIME(state, estimatedTime) {
    localStorage.setItem('warungprabowo.estimatedTime', estimatedTime)
  },
  SET_FINAL_PRICE(state, finalPrice) {
    state.finalPrice = finalPrice
  },
  SET_TABLE_ROW(state, tableRow) {
    state.tableRow = tableRow
  },
  SET_ORDER_TYPE(state, isDineIn) {
    state.isDineIn = isDineIn
  },
  SET_SERVING_TIME(state, servingTime) {
    state.servingTime = servingTime
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
