<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          INVESTOR PAGE
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Become an Investor and be a part of
        </p>
        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          the Future of Traditional Retail Data
        </p>
        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Analytics
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-32"></div>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/investor.png" class="w-full" />
        </div>
      </div>

      <div class="mb-32 flex w-full flex-col px-6 lg:px-32">
        <div class="flex-center mb-10 flex flex-col justify-start">
          <div class="flex w-full justify-center">
            <p
              class="text-center text-xs tracking-widest text-gray-400 md:text-base"
            >
              OUR METRICS SO FAR...
            </p>
          </div>
          <div class="mt-12 flex flex-row">
            <div class="flex w-1/4 items-center justify-end">
              <CircleLeft class="h-10 cursor-pointer" />
            </div>
            <div class="flex w-2/4 flex-col items-start justify-center">
              <div class="flex w-full flex-row justify-center">
                <div class="flex w-1/3 items-center justify-end">
                  <Philippines class="h-12 opacity-30" />
                </div>
                <div class="flex w-1/3 items-center justify-center">
                  <Indonesia class="h-28" />
                </div>
                <div class="flex w-1/3 items-center justify-start">
                  <India class="h-12 opacity-30" />
                </div>
              </div>
            </div>
            <div class="flex w-1/4 items-center justify-start">
              <CircleRight class="h-10 cursor-pointer" />
            </div>
          </div>
          <div class="mt-10 flex flex-col items-center justify-center">
            <div class="flex flex-col items-start justify-center">
              <p class="text-kira-secondary text-md leading-8">
                000 of outlets
              </p>
              <p class="text-kira-secondary text-md leading-8">
                000 of transactions
              </p>
              <p class="text-kira-secondary text-md leading-8">000 of brands</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div
          class="flex-center mb-10 flex flex-col justify-start transition duration-500 hover:scale-105"
        >
          <div class="flex">
            <p class="text-center text-sm font-black text-black md:text-xl">
              Warung Prabowo will revolutionize the traditional retail trade in the
              markets of Asia, South America, Africa and Eastern Europe by:
            </p>
          </div>
          <div class="mt-12 flex flex-row">
            <div class="flex pr-2">
              <CircleCheck class="text-kira-success h-6" />
            </div>
            <div class="flex items-start">
              <p class="text-xs leading-6 text-gray-400">
                Providing micro retailers with easy to access information and
                insight about their stores and community leading to greater
                profitability and cash flow and having a noticeable positive
                effect on the economies of those countries
              </p>
            </div>
          </div>
          <div class="mt-4 flex flex-row">
            <div class="flex pr-2">
              <CircleCheck class="text-kira-success h-6" />
            </div>
            <div class="flex items-start">
              <p class="text-xs leading-6 text-gray-400">
                Providing real-time market, sales, and inventory data to
                manufacturers and distributors to give the most comprehensive,
                real-time answers to their most difficult questions
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'

// Offers
import CircleCheck from '@/assets/svg/circle-check.svg'
import CircleRight from '@/assets/svg/circle-right.svg'
import CircleLeft from '@/assets/svg/circle-left.svg'
// Flags
import Indonesia from '@/assets/svg/flags/indonesia.svg'
import Philippines from '@/assets/svg/flags/philipphines.svg'
import India from '@/assets/svg/flags/india.svg'

export default {
  name: 'InvestorPage',
  components: {
    CircleCheck,
    CircleLeft,
    CircleRight,
    Indonesia,
    Philippines,
    India,
  },
  mixins: [globalMixin],
  data: () => ({}),
  methods: {},
}
</script>
