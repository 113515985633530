<template>
  <div
    class="flex-center mb-10 flex flex-col justify-start transition duration-500 hover:scale-105"
  >
    <div class="flex">
      <p class="text-md font-black text-white">{{ title }}</p>
    </div>
    <div class="mt-4 flex flex-row">
      <div v-if="showIcon" class="flex pr-2">
        <CircleCheck class="text-kira-secondary h-6" />
      </div>
      <div class="flex items-start">
        <p class="text-xs leading-6 text-gray-400">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CircleCheck from '@/assets/svg/circle-check.svg'

export default {
  name: 'CardThree',
  components: {
    CircleCheck,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    click() {
      this.$emit('onClick')
    },
  },
}
</script>
