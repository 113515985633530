<template>
  <div
    class="flex h-full transform flex-col items-center justify-between rounded-lg border p-6 transition duration-500 hover:scale-105"
  >
    <slot></slot>
    <p class="mt-4 text-sm text-white">{{ title }}</p>
    <p class="mt-4 text-xs leading-6 text-gray-400">
      {{ description }}
    </p>
    <!-- <div class="mt-4 flex cursor-pointer items-center" @click="click">
      <p class="text-kira-secondary text-sm">Pelajari Lebih Lanjut</p>
      <ArrowRightIcon class="text-kira-secondary aling-center ml-4 h-3" />
    </div> -->
  </div>
</template>

<script>
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'

export default {
  name: 'CardOne',
  components: {
    ArrowRightIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    click() {
      this.$emit('onClick')
    },
  },
}
</script>
