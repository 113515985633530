import { createStore } from 'vuex'
import global from './global'
import auth from './auth'
import cart from './cart'
import categories from './categories'
import home from './home'
import guest from './guest'
import order from './order'
import product from './product'
import user from './user'
import tenant from './tenant'
import institution from './institution'
import url from './url'
import checkout from './checkout'
import payment from './payment'
import promo from './promo'
import refund from './refund'
import waiter from './waiter'

export default createStore({
  modules: {
    global,
    auth,
    cart,
    categories,
    checkout,
    payment,
    promo,
    home,
    guest,
    order,
    product,
    refund,
    user,
    tenant,
    institution,
    url,
    waiter,
  },
})
